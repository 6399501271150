::selection {
  background: #ff4d5275;
}

body {
  margin: 0;
}

#root {
  display: flex;
  justify-content: center;
  background-color: #f2f2f0;
}

#atcb-bgoverlay {
  z-index: 1000000;
}

input[type='time']::-webkit-calendar-picker-indicator {
  opacity: 0.55;
  font-size: 24px;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div[tabindex]:focus-visible {
  outline: none;
}
